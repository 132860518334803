import { Menu, Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';

import { mergeClasses } from '@/utils/utils';

import { useInViewStore } from '../herobanner/store';

const LINKS = [
  { href: '#about', label: 'About' },
  { href: '#career', label: 'Career' },
  { href: '#services', label: 'Services' },
  { href: '#contact', label: 'Contact' },
];

const Navbar = () => {
  const inView = useInViewStore((state) => state.inView);

  return (
    <section className="fixed top-0 z-20 w-full">
      <div
        className={mergeClasses(
          'flex justify-center w-full bg-primary-700/50 drop-shadow-lg shadow-xl  backdrop-blur duration-200',
          { 'bg-transparent h-20 shadow-primary-700/30': inView },
          { 'h-16 shadow-primary-700/50': !inView }
        )}
      >
        <nav
          aria-label="navigation_bar"
          className="flex w-full max-w-screen-2xl items-center justify-between"
        >
          <div className="h-full w-1/3 md:hidden lg:flex"></div>
          <div
            className={mergeClasses(
              'm-auto mx-5 my-2 transition-all duration-200',
              { 'h-16 w-16': inView },
              { 'h-12 w-12': !inView }
            )}
          >
            <Link href="/">
              <Image
                src="/assets/images/icon_white.svg"
                alt="Logo"
                width="64"
                height="64"
                layout="responsive"
                className="h-16 w-full flex-none object-cover object-center text-white"
              />
            </Link>
          </div>
          <div className="my-auto flex w-1/3 justify-end  pr-5 md:hidden">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </Menu.Button>
              </div>
              <Transition
                as="div"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right  rounded-md bg-primary-500/60 shadow-lg ring-1 ring-black/5 backdrop-blur-sm focus:outline-none">
                  <div className="inline-flex items-center rounded px-5 pb-1 pt-3 font-bold text-white shadow-white/50 brightness-125">
                    &lt;<span className="text-accent-400/60">Links</span>&gt;
                  </div>
                  <div className="ml-4 p-1">
                    {LINKS.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item key={link.href} as="div">
                        {({ active }) => (
                          <Link
                            href={link.href}
                            key={link.href}
                            aria-label="nav_link"
                            className={
                              active
                                ? 'link ml-3 inline-flex cursor-pointer items-center rounded border-y-2 border-b-accent-500 border-t-transparent px-2 py-1 font-bold text-white shadow-white/50 brightness-150 hover:bg-black/30  hover:text-gray-300 hover:backdrop-blur-lg focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-primary-600/50'
                                : 'link ml-3 inline-flex cursor-pointer items-center rounded border-y-2 border-y-transparent px-2 py-1 font-bold text-white shadow-white/50 brightness-150  hover:bg-black/30 hover:text-gray-300 hover:backdrop-blur-lg focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-primary-600/50'
                            }
                          >
                            &lt;
                            <span className="text-accent-300">
                              {link.label}
                            </span>
                            &nbsp;/&gt;
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                  <div className="inline-flex items-center rounded px-5 pb-3 pt-1 font-bold text-white shadow-white/50 brightness-125">
                    &lt;/<span className="text-accent-400/60">Links</span>&gt;
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="hidden h-full items-center justify-end gap-3 pr-4 md:flex lg:w-1/3">
            {LINKS.map((link) => (
              /* Use the `active` state to conditionally style the active item. */
              <Link
                key={link.href}
                href={link.href}
                aria-label="nav_link"
                className=" inline-flex cursor-pointer items-center rounded rounded-b-none border-t-transparent p-1 font-bold text-white shadow-white/50 brightness-125 hover:border-y-2 hover:border-b-accent-500 hover:bg-black/30 hover:text-gray-300 hover:backdrop-blur-lg focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-primary-600/50"
              >
                {/* &lt;<span className="mr-1 text-secondary-500">{link.label} </span>
              &nbsp;/&gt; */}
                <span className="text-secondary-500">{link.label}</span>
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Navbar;
