import { create } from 'zustand';

interface InViewStore {
  inView: boolean;
  setInView: (status: boolean) => void;
}

export const useInViewStore = create<InViewStore>((set) => ({
  inView: false,
  setInView: (status) => set({ inView: status }),
}));
