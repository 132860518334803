// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';

import { type ReactNode, useEffect, useState } from 'react';

import { Footer } from '@/components/footer';
import { Navbar } from '@/components/navbar';

type LayoutProps = {
  meta: ReactNode;
  children?: ReactNode;
};

const Layout = ({ meta, children }: LayoutProps) => {
  const [isLocalhost, setIsLocalhost] = useState(false);
  useEffect(() => {
    setIsLocalhost(process.env.NODE_ENV === 'development');
  }, [process.env.NODE_ENV]);
  return (
    <div className="flex w-full flex-col bg-primary-500 text-secondary-500 antialiased">
      {meta}
      <Navbar />
      {isLocalhost && (
        <div className="fixed left-0 top-0 z-50 bg-primary-500 p-2 text-white">
          <span className="sm:hidden">Screen Size: xs</span>
          <span className="hidden sm:block md:hidden">Screen Size: sm</span>
          <span className="hidden md:block lg:hidden">Screen Size: md</span>
          <span className="hidden lg:block xl:hidden">Screen Size: lg</span>
          <span className="hidden xl:block">Screen Size: xl</span>
        </div>
      )}
      <div className="flex-1">
        <div>{children && children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
